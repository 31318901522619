import { get, set } from 'idb-keyval'

const PERSONAL_ACCESS_TOKEN = '1334-b734af44-9097-408b-9c25-e7c62ef63323'

function getFileKey(pageUrl) {
  const parser = document.createElement('a')
  parser.href = pageUrl
  return parser.pathname.replace('/file/', '').replace(/\/.*/,'')
}

function getNodeId(pageUrl) {
  const parser = document.createElement('a')
  parser.href = pageUrl
  const decoded = decodeURIComponent(parser.search)
  const regex = /[?&]node-id=([^&]+)/ // Regular expression pattern

  const match = decoded.match(regex)

  if (match) {
    const result = match[1]
    console.log("node", result)
    return result
  } else {
    console.log("node regex not found")
    return null
  }
}

function apiRequest(endpoint) {
  console.log("hit", 'https://api.figma.com/v1' + endpoint)
  return fetch('https://api.figma.com/v1' + endpoint, {
    method: 'GET',
    headers: { "x-figma-token": PERSONAL_ACCESS_TOKEN }
  }).then(function(response) {
    return response.json()
  }).catch(function (error) {
    return { err: error }
  })
}

export async function getFigmaImage(url, format="png", useCache=true) {
  const pageUrl = url
  const nodeId = getNodeId(pageUrl)

  return new Promise(async (resolve, reject) => {
    const data = {
      name: "Figma",
      type: "image",
      src: null
    }

    const cache = await get("image/" + nodeId)

    if (cache.src && useCache) {
      console.log("got item cache", nodeId, cache, cache.src !== 'null')
      resolve(cache)
    } else {
      console.log("no cache fig node", url)
      apiRequest('/images/' + getFileKey(pageUrl) + '?ids=' + nodeId + `&format=${format}`).then(function (apiResponse) {
        console.log("api", apiResponse, apiResponse.images)
        const imageUrl = apiResponse.images[nodeId]

        if (imageUrl && imageUrl != cache.src) {
          // addImageToCanvas(imageUrl, imgElement)
          console.log("fig image got", nodeId, imageUrl, '/images/' + getFileKey(pageUrl) + '?ids=' + nodeId + `&format=${format}`, apiResponse)

          data.src = imageUrl
          set("image/" + nodeId, data)
          resolve(data)
        } else {
          console.warn("fig image error", nodeId, url, '/images/' + getFileKey(pageUrl) + '?ids=' + nodeId + `&format=${format}`, apiResponse)
          reject()
        }
      }).catch(error => {
        console.log("fig api error", error)
        reject()
      })
    }

  })
}



export async function getFigmaNodes({url, page, node}) {
  // "https://www.figma.com/file/x105MEPt0PftFzb3rRVa6V/Spatial-Platform-2022?node-id=1559%3A21594"
  // https://www.figma.com/file/x105MEPt0PftFzb3rRVa6V/Spatial-Platform-2022?node-id=1559%3A21594

  const pageId = page || getFileKey(url) || null
  const nodeId = node || getNodeId(url) || null
  console.log("grab page", pageId, nodeId)
  const data = await getNodeData(pageId, nodeId, true)
  const dataWithPositions = formatPositionsAndSizes(data)
  console.log('data', dataWithPositions)
  return {data: dataWithPositions, page: pageId, node: nodeId}
}


function getNodeData(pageId, nodeId, useCache=true) {
  return new Promise(async (resolve, reject) => {
    // https://www.figma.com/file/:key/:title?node-id=:id.
    // GET /v1/files/:key/nodes?ids=1:2,1:3&depth=1
    const cache = await get(`${pageId}/${nodeId}`)

    if (cache && useCache) {
      console.log("got item cache")
      resolve(cache)
    } else {
      // hit figma api
      apiRequest('/files/' + pageId + '/nodes?ids=' + nodeId + `&depth=1&geometry=paths`).then((apiResponse) => {
        // flatten nested children
        const doc = Object.values(apiResponse.nodes)[0].document
        const flat = flattenObjectWithChildren(doc)

        const nodeStringArray = Object.values(flat).map(val => val.id).join(',')

        const imagesUrl = '/images/' + pageId + '?ids=' + nodeStringArray + `&format=${"svg"}`

        apiRequest(imagesUrl).then((res) => {
          if (res.images) {
            // get images and store to flat
            Object.values(res.images).map((val, i) => {
              flat[i].src = val
            })
            console.log("set api response", apiResponse.nodes, flat)
            set(`${pageId}/${nodeId}`, flat)
            resolve(flat)
          }
        })
      }).catch(error => {
        console.log("fig api error", error)
      })
    }
  })
}

function formatPositionsAndSizes(data) {
  // calc positions and sizes
  // no need i see to cache the positions and sizes so we calc each time
  console.log("flat", data)

  // this makes everything map to n
  const bbox = data[0].absoluteBoundingBox
  const scaleFactor = 10 / Math.max(bbox.width, bbox.height)
  const xOffset = -bbox.width/2
  const yOffset = bbox.height

  const newData = data.flatMap((val, i) => {
    // this adds the bbox.width/2 puts the anchor point top-left since figma anchor is top-left
    // note: figma y-up is -1 and y-down is +1 vs threejs is +1/-1 respectively therefore inverse y
    const depth = i !== 0 ? 0 : .05 //if its the root, just use it as a bg. may not work everywhre
    const padding = 1
    const size = [val.size.x * scaleFactor, val.size.y * scaleFactor, depth]
    const x = ((val.absoluteBoundingBox.width/2) + (val.absoluteBoundingBox.x - bbox.x) + xOffset) * scaleFactor
    const y = ((val.absoluteBoundingBox.height/-2) + ((val.absoluteBoundingBox.y - bbox.y) * -1) + yOffset) * scaleFactor
    const position = [x, y, i * padding]

    // console.log("positions", val.id, position, size, val.absoluteBoundingBox.y, bbox.y, yOffset)

    // if it's the root canvas and bg is transparent skip it entirely
    // see: https://stackoverflow.com/questions/24806772/how-to-skip-over-an-element-in-map
    if (i === 0 && val.backgroundColor?.a === 0) return []

    return {
      id: val.id,
      name: val.name,
      src: i === 0 ? null : val.src, // hack to not pass src for index since it renders the entire children
      color: val.backgroundColor ? mapColorTo256(val.backgroundColor) : null,
      type: "image",
      figmaType: val.type,
      size: size,
      position: position,
      // url: `https://www.figma.com/file/${pageId}/?node-id=${key}`
    }

  })

  return newData
}

function mapColorTo256(color) {
  return `rgba(${color.r*255},${color.g*255},${color.b*255},${color.a})`
}

function flattenObjectWithChildren(obj) {
  let result = []

  function traverse(object, parentId=null) {
    const { children, ...rest } = object
    const newObj = { ...rest, parentId }
    result.push(newObj);

    if (children && children.length > 0) {
      children.forEach(child => {
        traverse(child, object.id)
      });
    }
  }

  traverse(obj) // Start the traversal with the root object
  return result
}


// function createGalleryFromArray(sortedNamedArray, gridContainerElement, existingArray) {
//   sortedNamedArray.forEach(([index, val]) => {
//     // if we specify existing array, we shoudl remove the ones that no longer exist

//     const childDiv = $(gridContainerElement).children(`div[key="${val.id}"]`)

//     if (existingArray) {
//       const checkExistence = existingArray.some(([existingKey, existingVal]) => {
//         return existingVal.id === val.id
//       })

//       if (!checkExistence) {
//         // if it doesnt exist anymore lets delete it
//         console.log("removing", childDiv)
//         childDiv.remove()
//       }
//     }

//     if (childDiv.length !== 0) {
//       // console.log("it exists, just replace", childDiv)
//       childDiv.find(`img`).each(function(index) {
//         if (this.src !== val.src) {
//           console.log('img src replace', this.src, val.src, this.src===val.src)
//           this.src = val.src
//         }
//       })

//       childDiv.find(`a`).each(function(index) {
//         if (this.href !== val.url) {
//           console.log('a href replace', this.src, val.src, this.src===val.src)
//           this.href = val.url
//         }
//       })

//       childDiv.find(`span`).each(function(index) {
//         if (this.textContent !== val.name) {
//           console.log('span text replace', this.src, val.src, this.src===val.src)
//           this.textContent = val.name.toLowerCase()
//         }
//       })
//     } else {
//       // console.log("it dont exist, so create it")
//       const containerElement = document.createElement('div')
//       containerElement.setAttribute("key", val.id)
//       containerElement.classList.add("size-64", "center", "p2", "inline-block")

//       const imgElement = document.createElement('img')
//       imgElement.src = val.src
//       imgElement.name = val.name
//       imgElement.classList.add("size-32", "m0")

//       const nameElement = document.createElement('span')
//       nameElement.classList.add("h6", "nowrap", "block", "full-width", "overflow-auto")
//       nameElement.textContent = val.name.toLowerCase()

//       containerElement.appendChild(imgElement)
//       containerElement.appendChild(nameElement)
//       $(imgElement).wrap(`<a target="_blank" href='${val.url}'></a>`)

//       gridContainerElement.appendChild(containerElement)
//     }
//   })
// }

function replaceElement(source, target, replace=true) {
  const classes = source.getAttribute("class")

  if (classes) {
    target.setAttribute("class", classes)
  }

  if (replace) {
    source.parentNode.replaceChild(target, source)
  }
}

function getFigmaChildren(url, cb) {
  const pageUrl = url
  const nodeId = getNodeId(pageUrl)

  apiRequest('/files/' + getFileKey(pageUrl) + '/nodes?ids=' + nodeId)
    .then( (res) => {
      // const imageUrl = apiResponse.images[nodeId]
      Object.keys(res.nodes).forEach( node => {
        // console.log(res.nodes[node].document.children)
        const children = res.nodes[node].document.children

        let imageIDs = []
        let imageData = {}

        children.map( item => {
          const types = ["FRAME", "COMPONENT", "INSTANCE", "GROUP"]
          if (types.includes(item.type)) {
            imageIDs.push(item.id)
            imageData[item.id] = item
          }
        })

        apiRequest('/images/' + getFileKey(pageUrl) + '?ids=' + imageIDs.join() + '&format=png')
          .then(function (res) {
            // image.url = res.images

            Object.keys(res.images).forEach( imageID => {
              // console.log("single", imageID, imageData[imageID])
              let image = imageData[imageID].absoluteBoundingBox
              image.url = res.images[imageID]
              cb(image)
            })

            // console.log("image response", imageUrl, boundingBox, apiResponse)
          }).catch(function (error) {
            return error
          })
      })

    })
}


// $("#content a").each((index, value ) => {
//   const linkElement = $(this)
//   const figmaURL = value.href

//   if (value.text === "fig-svg" || value.text === "figma-svg") {
//     const imgElement = document.createElement('img')
//     // console.log("get fig svg", value.href, value.pathname)

//     imgElement.name = value.pathname
//     replaceElement(value, imgElement)
//     const imgElementJ = $(imgElement)
//     callFigmaAndDrawMockups(figmaURL, imgElementJ, "svg")
//   } else if (value.text === "fig" || value.text === "figma") {
//     const imgElement = document.createElement('img')
//     imgElement.name = value.pathname
//     replaceElement(value, imgElement)
//     const imgElementJ = $(imgElement)
//     // console.log("get fig", value.href, value.pathname)
//     callFigmaAndDrawMockups(figmaURL, imgElementJ, "png")
//   } else if (value.text === "fig-gallery") {
//     console.log('get fig gallery')
//     const galleryContainer = document.createElement('div')
//     galleryContainer.name = value.pathname

//     replaceElement(value, galleryContainer)
//     getFigmaNodes(figmaURL, galleryContainer)
//   }
// })