// help: https://stackoverflow.com/questions/62525452/passing-state-vs-using-context-in-react-hooks
import { createContext, useContext } from "react"

export const CameraContext = createContext(null)
export const ObjectsContext = createContext(null)
export const SelectionContext = createContext(null)
export const DraggingSelectionContext = createContext(null)

export function useCameraContext() {
  const context = useContext(CameraContext)
  if (context === undefined) {
    throw new Error('useObjectsContext must be used within an ObjectsContext.Provider')
  }
  return context
}

export function useObjectsContext() {
  const context = useContext(ObjectsContext)
  if (context === undefined) {
    throw new Error('useObjectsContext must be used within an ObjectsContext.Provider')
  }
  return context
}

export function useSelectionContext() {
  const context = useContext(SelectionContext)
  if (context === undefined) {
    throw new Error('useObjectsContext must be used within an ObjectsContext.Provider')
  }
  return context
}

export function useDraggingSelectionContext() {
  const context = useContext(DraggingSelectionContext)
  if (context === undefined) {
    throw new Error('useObjectsContext must be used within an ObjectsContext.Provider')
  }
  return context
}