// see: https://stackoverflow.com/questions/69955057/how-to-control-movement-of-a-person-in-react-three-fiber
import { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { useFrame } from '@react-three/fiber'

import { debounce } from './Utils'
import { useSelectionContext, useObjectsContext } from "./SceneContext"


export const useKeys = () => {

  const keys = {
    // KeyW: 'forward',
    // KeyS: 'backward',
    // KeyA: 'left',
    // KeyD: 'right',
    // Space: 'jump',

    KeyC: 'circle',
    KeyF: 'frame',
    KeyI: 'icon',
    KeyS: 'fitSelection',
    KeyT: 'text',
    KeyV: 'cursor',
    Space: 'jump',
    Escape: 'escape',
    Backspace: 'delete',
    Enter: 'enter',

    Input: "input",

    // modify camera controls keys see: view-source:https://yomotsu.github.io/camera-controls/examples/mouse-drag-with-modifier-keys.html
    ShiftLeft: 'truck',
    ShiftRight: 'truck',
    ControlLeft: 'dolly',
    ControlRight: 'dolly',
    AltLeft: 'rotate',
    AltRight: 'rotate',
    MetaLeft: 'rotate',
    MetaRight: 'rotate',

    Wheel: 'mouseDolly'
  }


  const entries = useMemo(() => Object.fromEntries(Object.entries(keys).map(([key, val]) => [val, false])), [keys])
  const [action, setAction] = useState(entries)

  const moveFieldByKey = (key) => keys[key]

  useEffect(() => {
    const handleKeyDown = (e) => {
      // console.log("e", e.code)
      const buttonFocused = e.target.tagName === "BUTTON"
      if (buttonFocused) {
        e.preventDefault()
      }

      const inputFocused = e.target.tagName === "INPUT"
      // console.log('in', inputFocused, buttonFocused)
      if (inputFocused) {
        setAction((m) => ({ ...m, [moveFieldByKey("Input")]: true }))
      } else {
        setAction((m) => ({ ...m, [moveFieldByKey("Input")]: false }))
      }

      setAction((m) => ({ ...m, [moveFieldByKey(e.code)]: true }))
    }

    const handleKeyUp = (e) => {
      setAction((m) => ({ ...m, [moveFieldByKey(e.code)]: false }))
    }

    const handleMouseWheel = (e) => {
      // crazy hack but, this guy says ctrlkey is zoom https://kenneth.io/post/detecting-multi-touch-trackpad-gestures-in-javascript
      if (e.ctrlKey) {
        // console.log("ctrl", e)
        setAction((m) => ({ ...m, [moveFieldByKey("Wheel")]: true }))
      } else {
        // console.log("not ctrl", e)
        setAction((m) => ({ ...m, [moveFieldByKey("Wheel")]: false }))
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener("wheel", handleMouseWheel)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener("wheel", handleMouseWheel)
    }
  }, [])

  return action
}

export function KeyCommands({fitSelection, setIsDrawing, createObject, cameraRef}) {
  const keys = useKeys()
  const currentAction = useRef(null)
  const nextAction = useRef(null)

  const [selection, setSelection] = useSelectionContext()
  const [objects, setObjects] = useObjectsContext()

  const deleteSelection = useCallback(() => {
    if (selection) {
      setObjects((prevData) => {
        const newData = {...prevData}
        delete newData[selection]
        return newData
      })
    }

  }, [selection, objects, setSelection, setObjects])

  const doAction = useCallback((action) => {
    if (currentAction.current !== nextAction.current) {
      action()
      currentAction.current = nextAction.current
    }

  }, [currentAction, keys])


  // from https://github.com/yomotsu/camera-controls/blob/dev/src/types.ts
  const ACTION = {
    NONE: 0,
    ROTATE: 1,
    TRUCK: 2,
    OFFSET: 4,
    DOLLY: 8,
    ZOOM: 16,
    TOUCH_ROTATE: 32,
    TOUCH_TRUCK: 64,
    TOUCH_OFFSET: 128,
    TOUCH_DOLLY: 256,
    TOUCH_ZOOM: 512,
    TOUCH_DOLLY_TRUCK: 1024,
    TOUCH_DOLLY_OFFSET: 2048,
    TOUCH_DOLLY_ROTATE: 4096,
    TOUCH_ZOOM_TRUCK: 8192,
    TOUCH_ZOOM_OFFSET: 16384,
    TOUCH_ZOOM_ROTATE: 32768,
  }

  useFrame(() => {
    if (cameraRef.current) {
      // console.log("cam", cameraRef.current.mouseButtons)
      if (keys.truck) {
        cameraRef.current.mouseButtons.left = ACTION.TRUCK
      } else if (keys.dolly) {
        cameraRef.current.mouseButtons.left = ACTION.DOLLY
      } else {
        cameraRef.current.mouseButtons.left = ACTION.ROTATE
        cameraRef.current.mouseButtons.middle = ACTION.TRUCK
      }

      if (keys.mouseDolly) {
        cameraRef.current.mouseButtons.wheel = ACTION.DOLLY
      } else {
        cameraRef.current.mouseButtons.wheel = ACTION.TRUCK
      }
    }

    nextAction.current = Object.entries(keys).filter(([key, val]) => val)?.[0]?.[0]

    // general escaper whether its input or not
    if (keys.escape || keys.enter) {
      setIsDrawing(false)
      setSelection(null)
      resetInput()
    }

    // perform these actions if it's not an input field
    if (!keys.input) {
      if (keys.fitSelection) {
        doAction(fitSelection)
      }

      if (keys.cursor || keys.escape) {
        doAction(() => setIsDrawing(false))
      }

      if (keys.frame) {
        doAction(() => setIsDrawing("frame"))
      }

      if (keys.circle) {
        doAction(() => setIsDrawing("circle"))
      }

      if (keys.text) {
        doAction(() => setIsDrawing("text"))
      }

      if (keys.icon) {
        doAction(() => setIsDrawing("icon"))
      }

      if (keys.delete) {
        doAction(deleteSelection)
      }

      if (keys.enter) {
        doAction(() => console.log("enter"))
      }
    }

    // reset next action if nothings on
    if (!nextAction.current && currentAction.current) {
      currentAction.current = null
    }
  })

  return
}


export function resetInput() {
  // console.log('reset')
  const inputHelper = document.getElementById("inputTextHelper")

  if (inputHelper) {
    inputHelper["value"] = ""
    inputHelper.blur()
    inputHelper.disabled = true

    const new_element = inputHelper.cloneNode(true)
    inputHelper.parentNode.replaceChild(new_element, inputHelper)
  }
}