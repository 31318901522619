import { Toaster } from 'sonner'
import Scene from './components/Scene'
import SceneProvider from './components/SceneProvider'

// export async function loader({ params }) {

export default function Root() {
  return (
    <div className="w-screen h-dvh relative">
      <Toaster
        position="bottom-center"
        toastOptions={{
          className: 'rounded-md bg-white/10 backdrop-blur-sm border-none text-white text-center justify-center',
        }}
      />

      <SceneProvider>
        <Scene />
      </SceneProvider>
    </div>
  )
}