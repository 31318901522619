import { motion } from 'framer-motion'

import MaterialSymbol from "./MaterialSymbol"

export default function LayerButton({name, i, icon, selected, onClick, onDelete}) {
  return (
    <motion.div
      className="flex gap-2"
      style={{ originX: 0, originY: 0 }}
      initial={{scale: .5, opacity: 0}}
      animate={{scale: 1, opacity: 1}}
      exit={{scale: 0, opacity: 0}}
      transition={{type: "spring", stiffness: 400, damping: 24}}
    >
      <button
        className={`${selected ? "text-white opacity-100" : "text-white opacity-40"} flex-1 hover:opacity-80 text-left capitalize text-sm font-medium py-1 overflow-hidden whitespace-nowrap text-ellipsis`}
        onClick={onClick}
      >
          {name}
      </button>

      {selected &&
        <button
          className={"opacity-40 flex-none"}
          onClick={onDelete}
        >
          <MaterialSymbol name="close" weight={400} grade={-25} opticalSize={20} />
        </button>
      }
    </motion.div>
  )
}


