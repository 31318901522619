import MaterialSymbol from "./MaterialSymbol"
import { motion } from "framer-motion"
import { useState, useEffect } from 'react'

export default function Dropdown({name, children, icon=false, className, onClick}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`relative`}>
      <button
        // class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        className={`flex items-center pointer-events-auto bg-white/20 hover:bg-white/40 capitalize rounded text-sm ${icon ? "pl-2 pr-1" : "px-2"} py-1 ${className}`}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {name}
        {icon &&
          <MaterialSymbol className="text-white backdrop-blur" name={"arrow_drop_down"} />
        }

      </button>

      <motion.div
        className="absolute left-0 origin-top-left z-10 py-2 mt-2 min-w-24 rounded-md bg-white/20"
        animate={{scale: isOpen ? 1 : 0}}
        transition={{type: "spring", stiffness: 400, damping: 24}}
        onClick={() => {
          setIsOpen(false)
        }}
        // pointerOut={() => {
        //   console.log('point out')
        // }}
      >
        <div className="pointer-events-auto pl-3 pr-4 py-1 items-start flex flex-col gap-2 align-right">
          {children}
        </div>
      </motion.div>
    </div>
  )
}
