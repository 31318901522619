// started from https://codesandbox.io/p/sandbox/inter-epoxy-resin-lxvqek?
import { useRef, useEffect, useState, useCallback, useTransition } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { useControls, useStoreContext, LevaPanel } from 'leva'

import { Vector3, PointLightHelper, Matrix4, Raycaster, BoxHelper, GridHelper, ClampToEdgeWrapping, RepeatWrapping, ImageLoader } from "three"
import { EffectComposer, Bloom, HueSaturation, BrightnessContrast } from '@react-three/postprocessing'

import { Canvas, useFrame, useLoader } from '@react-three/fiber'

import { generateUID, isIOS } from "./Utils"
import { levaTheme } from "./Themes"
import FigmaSVG from "../figma.svg"
import { toast } from 'sonner'

import {
  CycleRaycast,
  Environment,
  ContactShadows,
  CameraControls,
  RandomizedLight,
  Sky,
  Helper,
  AccumulativeShadows,
  useTexture,
} from '@react-three/drei'

import MaterialSymbol from "./MaterialSymbol"
import IconButton from "./IconButton"
import Dropdown from "./Dropdown"
import Sidebar from "./Sidebar"

import Gizmo from "./Gizmo"
import { useKeys, KeyCommands, resetInput } from "./Keys"
import InputHelpers, { getFileInput, importFigma } from "./InputHelpers"
import { Exporter } from "./ExportHelpers"


import LayerButton from "./LayerButton"
import { useDraggingSelectionContext, useSelectionContext, useObjectsContext, useCameraContext } from "./SceneContext"

import { Grid, Light, Circle, TextField, ObjectCreator, Frame, ImageFrame, Cube, Sphere, GLTF } from "./ObjectCreator"

import { useSearchParams } from "react-router-dom"

// const { getState, setState, subscribe, destroy } = store

// const useSceneGraph = create((set) => ({
//   sceneGraph: {},
//   setSceneGraph: (sceneGraph) => set({sceneGraph}),
//   removeSceneGraph: () => set({}),
// }))

// https://github.com/pmndrs/react-three-fiber/issues/1249



export default function Scene(props) {
  // contexts
  const sceneStore = useStoreContext()
  const [transformInput, setTransformInput] = useCameraContext()
  const [objects, setObjects] = useObjectsContext()
  const [selection, setSelection] = useSelectionContext()
  const [draggingSelection, setDraggingSelection] = useDraggingSelectionContext()

  // states
  const [isExporting, setIsExporting] = useState(false)
  const [sceneGraph, setSceneGraph] = useState()
  const [hoveredSelection, setHoveredSelection] = useState([])
  const [isDrawing, setIsDrawing] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  // refs
  const objectsRef = useRef(null)
  const cameraRef = useRef(null)
  const previewRef = useRef(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const init = useCallback(async (camera) => {
    // this is mount
    // console.log("cam ref", camera.current, cameraRef.current)
    if (cameraRef.current) {
      // console.log('mount', mountedRef.current)
      const offset = 2
      await cameraRef.current.dollyTo(2000, false)
      await cameraRef.current.setTarget(0, 0, 0, false)

      cameraRef.current.dollyTo(500, true)
      cameraRef.current.setTarget(0, offset, 0, true)
    }
  }, [cameraRef.current])

  // todo this should be a context provider otherwise we're mounting twice or even more
  const keys = useKeys()

  const isDraggable = useCallback((key) => {
    // console.log('keys', keys.rotate)
    if (!isDrawing && (!draggingSelection && hoveredSelection?.[0]?.object?.uid === key) || (draggingSelection === key)) {
      if (keys.rotate || keys.truck || keys.offset || keys.dolly) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }, [keys, isDrawing, draggingSelection, hoveredSelection])


  const handleTransformInput = useCallback((e) => {
    // const camera = cameraRef()
    const rotation = e.target._spherical
    if (rotation.phi < Math.PI/3) {
      // console.log("xz only, disable y")
      setTransformInput([true, false, true])
    } else {
      if (Math.abs(rotation.theta % Math.PI) < Math.PI/3 || Math.abs(rotation.theta % Math.PI) > Math.PI*2/3) {
        // console.log("xy only, disable z")
        setTransformInput([true, true, false])
      } else {
        // console.log("yz only, disable x")
        setTransformInput([false, true, true])
      }
    }


  }, [])

  const handleTransformStartInput = useCallback((e) => {
    setIsDragging(true)
  }, [])

  const handleTransformEndInput = useCallback((e) => {
    setIsDragging(false)
  }, [])

  // fit/frame selection
  const fitSelection = useCallback(async() => {
    console.log("fit")
    // const camera = cameraRef()
    if (cameraRef.current && objects[selection]) {
      // console.log("get", objects[selection])
      const target = objects[selection]?.ref ? Object.values(objects[selection].ref.position) : [0, 0, 0]
      const targetOffset = Object.values(new Vector3(...target).add(new Vector3(0, 0, 0)))
      // const target = [0, 0, 0]
      // console.log("frame", objects[selection], target)
      // await cameraRef.current.moveTo(...targetOffset, true) //this one works well if the orbit point isn't set
      console.log("select", objects[selection]?.ref)
      await cameraRef.current.fitToBox(objects[selection]?.ref, true, {
        paddingTop: 1,
        paddingright: 1,
        paddingBottom: 1,
        paddingLeft: 1,
      })
    }
  }, [selection])


  // useEffect(() => {
  //   if (!selection) {
  //     console.log('reset input')
  //     resetInput()
  //   }
  // }, [selection])

  const createObject = useCallback((props) => {
    const uid = generateUID()

    const object = {
      ...props,
      uid: uid
    }

    setObjects(oldObjects => {
      return (
        {
          ...oldObjects,
          [uid]: object,
        })
    })
  }, [setObjects, generateUID])


  // check if it's a figma link and get the nodes
  useEffect(() => {
    const page = searchParams.get("page")
    const node = searchParams.get("node")

    if (page && node) {
      console.log("page node", page, node)
      importFigma({page, node, createObject})

      // hard code mickey
      if (page === "1nPoDX0bNvsaCAAesM3kxS") {
        console.log("get mickey")
        createObject({
          type: "gltf",
          src: "gltf/mickey.glb",
          position: [2, 0, -.5],
          rotation: [0, -Math.PI/6, 0],
        })
      } else if (page === "VhCaqj0mMH9E08BhbxakLj") {
        createObject({
          type: "gltf",
          src: "gltf/rpm_dude.glb",
          position: [0, -1, -1],
          rotation: [0, -Math.PI/6, 0],
          size: [3, 3, 3]
        })

        // createObject({
        //   type: "gltf",
        //   src: "gltf/novo.glb",
        //   position: [0, -4, -5],
        //   rotation: [0, 0, 0],
        //   size: [.08, .08, .08]
        // })

        // createObject({
        //   type: "gltf",
        //   src: "gltf/cyberpunk_station.glb",
        //   position: [-1, 0, -1],
        //   rotation: [0, 0, 0],
        //   size: [4, 4, 4]
        // })

        // createObject({
        //   type: "sphere",
        //   position: [0, 0, 0],
        //   rotation: [0, 0, 0],
        //   size: 100,
        //   src: "images/auditorium.png"
        // })


      }
    }

    console.log('use effect fire once')
  }, [])

  useEffect(() => {
    const lastSelection = Object.keys(objects).pop()
    setSelection(lastSelection)
  }, [objects])

  return (
    <>
      <div id="toolbar" className="text-white flex flex-col h-full lg:h-auto lg:w-full lg:flex-row z-40 absolute p-2 sm:p-4 gap-1 justify-center items-center pointer-events-none">
        <IconButton
          name="Cursor"
          icon="arrow_selector_tool"
          isActive={!isDrawing}
          onClick={() => setIsDrawing(false)}
        />

        <IconButton
          name="Frame"
          icon="crop_square"
          isActive={isDrawing === "frame"}
          onClick={(e) => {
            setIsDrawing("frame")
            e.stopPropagation()
          }}
        />

        <IconButton
          name="Circle"
          icon="circle"
          isActive={isDrawing === "circle"}
          onClick={() => setIsDrawing("circle")}
        />

        <IconButton
          name="Text"
          icon="format_size"
          isActive={isDrawing === "text"}
          onClick={() => setIsDrawing("text")}
        />

        <hr className={"w-px mx-2 opacity-0"}/>

        <IconButton
          name="Icon"
          icon="emoji_symbols"
          isActive={isDrawing === "icon"}
          onClick={() => setIsDrawing("icon")}
        />

        <IconButton
          name="Cube"
          icon="deployed_code"
          // isActive={isDrawing}
          onClick={() => createObject({type: "cube"})}
        />

        <IconButton
          name="Sphere"
          icon="ev_shadow"
          // isActive={isDrawing}
          onClick={() => createObject({type: "sphere"})}
        />

        <IconButton
          name="Light"
          icon="wb_incandescent"
          // isActive={isDrawing}
          onClick={() => createObject({type: "light"})}
        />

        <IconButton
          name="GLB"
          icon="upload_2"
          // isActive={isDrawing}
          onClick={(e) => getFileInput(e, createObject)}
        />

        <IconButton
          name="Figma"
          src={FigmaSVG}
          onClick={async (e) => {
            toast('paste a link to a figma node')
          }}
        />
        {/*<hr className={"w-px mx-2 h-6 bg-black opacity-20"}/>*/}
        <hr className={"w-px mx-2 opacity-0"}/>

        <Dropdown
          name="export"
          className={`${isIOS() ? "hidden" : "" } sm:flex my-4 sm:my-0`}
        >
          <button
            name="render"
            className="flex gap-2 items-center opacity-80 hover:opacity-100"
            onClick={() => {
              setIsExporting("png")
            }}
          >
            <MaterialSymbol className="text-white" opticalSize={20} name={"image"} />
            png
          </button>

          <button
            name="gltf"
            className="flex gap-2 items-center opacity-80 hover:opacity-100"
            onClick={() => {
              setIsExporting("gltf")
            }}
          >
            <MaterialSymbol className="text-white" opticalSize={20} name={"deployed_code_update"} />
            gltf
          </button>

          <button
            name="usdz"
            className="flex gap-2 items-center opacity-80 hover:opacity-100"
            onClick={() => {
              setIsExporting("usdz")
            }}
          >
            <MaterialSymbol className="text-white" opticalSize={20} name={"view_in_ar"} />
            usdz
          </button>
        </Dropdown>

{/*        {isIOS() &&
          <>
            <IconButton
              name="Play"
              icon="play_circle"
              onClick={() => {
                setIsExporting("preview")
              }}
            />
          </>
        }*/}

      </div>

      <div className="text-sm text-center w-full text-white flex z-40 absolute bottom-0 w-full pb-4 px-4 justify-center items-center pointer-events-none">

        {/* if ios, show an ar previewer for usdz */}
        {isIOS() ?
          <>
            <button
              className={"backdrop-blur-[2px] pointer-events-auto flex items-center bg-white/15 hover:bg-white/40 rounded pl-3 pr-2 gap-2 py-2 mb-2"}
              onClick={() => {
                setIsExporting("preview")
              }}
            >
              <span className="pt-px">View in AR</span>
              <MaterialSymbol
                className={""}
                name={"view_in_ar"}
              />
            </button>

            <a
              className="flex absolute hidden"
              ref={previewRef}
              id="link"
              rel="ar"
              href=""
              download="asset.usdz"
            >
              <img id="button" />
            </a>
          </>
        :
          <>
            <span className="opacity-40">drag to orbit, r-click/shift to pan, pinch/ctrl to zoom</span>
            <IconButton
              className="absolute bottom-0 right-0 m-2"
              name="Fit Selection"
              icon="filter_center_focus"
              // isActive={}
              onClick={fitSelection}
            />
          </>
        }

      </div>

      {objects && Object.keys(objects).length !== 0 &&
        <div className="p-2 sm:p-4 absolute z-40 max-h-dvh overflox-x-auto overflow-y-auto">
          {/*<MaterialSymbol className="text-white backdrop-blur" name={"menu"} />*/}
          <motion.div layoutScroll className={"rounded-md min-w-24 max-w-48 backdrop-blur-lg bg-white/10 text-white flex flex-col px-4 py-4"}>
            <AnimatePresence>
              {Object.entries(objects).map(([key, val], i) => {
                return (
                  <LayerButton
                    key={`layer_${key}`}

                    name={`${val.name || val.type} ${i+1}`}
                    selected={selection === key}

                    onClick={() => {
                      // set selection but find store somehow within
                      setSelection(key)
                    }}

                    onDelete={() => {
                      setObjects((prevData) => {
                        const newData = {...prevData}
                        delete newData[key]
                        return newData
                      })

                      setSelection(null)
                    }}
                  />
              )})}
            </AnimatePresence>
          </motion.div>
        </div>
      }

      <Sidebar>
        <div className="absolute z-20 p-2 sm:p-4 max-h-dvh overflow-y-auto right-0 top-0">
          <div className="overflow-hidden min-w-24 w-64 rounded-md h-fit bg-white/10 backdrop-blur-lg pt-px"
          >
            <LevaPanel
              key="levaScene"
              store={sceneStore}
              theme={levaTheme}
              titleBar={false}
              drag={true}
              hideCopyButton={true}
              flat
              fill
              // collapsed
            />

            {objects[selection]?.store &&
              <div className="">
                <div className="bg-white/10 pt-1 pb-1 px-2">
                  <hr className="opacity-50" />
                </div>
                <LevaPanel
                  key="levaObject"
                  store={objects[selection]?.store}
                  titleBar={false}
                  theme={levaTheme}
                  fill
                  flat
                />
              </div>
            }
          </div>

        </div>
      </Sidebar>

      {/*helps with input*/}
      <InputHelpers createObject={createObject} />

      <Canvas
        // colorManagement={false}
        // linear flat

        className={isDrawing ? "cursor-crosshair" : hoveredSelection.length ? "cursor-pointer" : isDragging ? "cursor-grabbing" : "cursor-grab"}
        onCreated={({ set, get }) => {
          // setSceneGraph({ set, get })
          init(cameraRef)
        }}
        // onCreated={(state) => setSceneGraph(state.get().scene)}
        shadows
        // orthographic
        // camera={{ position: [200, 200, 500], fov: 55, zoom: 80, far: 10000 }}
        camera={{ position: [20, 20, 50], fov: 10 }}

        // antialias={"true"}
        // alpha={transparent ? "true" : "false"}
        // powerPreference={"high-performance"}

        gl={{ preserveDrawingBuffer: true }}
        dpr={[1, 2]}
        // onPointerUp={(e) => {
        //   // console.log("you clicked", e)
        // }}
        // onPointerMissed={() => setTarget(null)}
        onPointerMissed={(e) => {
          console.log("pointer miss, clear selection")
          setSelection(null)

          // setTarget(null)
          resetInput()
        }}
      >

        <group ref={objectsRef}>
          {objects &&
            Object.entries(objects).map(([key, val], i) => {
              return (
                <>
                  {
                    val.type === "frame" ?
                      <Frame
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name="Box"
                        position={val.position}
                        rotation={val.rotation}
                        size={val.size}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    : val.type === "circle" ?
                      <Circle
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name="Circle"
                        position={val.position}
                        rotation={val.rotation}
                        size={val.size}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    : val.type === "text" ?
                      <TextField
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name="Text"
                        position={val.position}
                        rotation={val.rotation}
                        size={val.size}

                        type={"text"}
                        depth={val.depth}
                        font={val.font}
                        text={val.text}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    : val.type === "icon" ?
                      <TextField
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name="Icon"
                        position={val.position}
                        rotation={val.rotation}
                        size={val.size}

                        type={"icon"}
                        depth={val.depth}
                        font={val.font}
                        text={val.text}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    : val.type === "sphere" ?
                      <Sphere
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name={val.type}
                        type={val.type}

                        position={val.position}
                        rotation={val.rotation}
                        size={val.size}
                        src={val.src}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    : val.type === "gltf" ?
                      <GLTF
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name={val.name}
                        type={val.type}
                        position={val.position}
                        rotation={val.rotation}
                        size={val.size}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                        src={val.src}
                      />
                    :
                    val.type === "image" ?
                      <ImageFrame
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name={val.name}
                        type={val.type}
                        size={val.size}
                        position={val.position}
                        color={val.color}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}

                        src={val.src}
                      />
                    :
                    val.type === "light" ?
                      <Light
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name={val.type}
                        type={val.type}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    :
                      <Cube
                        key={`${val.type}_${key}`}
                        i={i}
                        uid={key}
                        name={val.type}
                        type={val.type}

                        hovered={hoveredSelection?.[0]?.object?.uid === key}
                        selected={selection === key}
                        draggable={isDraggable(key)}
                      />
                    }
                </>
              )
            })
          }
        </group>

        {isDrawing &&
          <ObjectCreator
            hoveredSelection={hoveredSelection}
            type={isDrawing}
            onEnd={(res) => {
              // create the object and send to the object loop
              // console.log("res", res)
              // todo: make set selection to move around
              setIsDrawing(false)
              createObject(res)
            }}
          />
        }

        {/*{target && <TransformControls object={target} mode={mode} />}*/}

        {/** Controls */}
        <KeyCommands
          cameraRef={cameraRef}
          fitSelection={fitSelection}
          setIsDrawing={setIsDrawing}
          createObject={createObject}
        />

        <CameraControls
          ref={cameraRef}
          dollyToCursor={true}
          enabled={isDrawing ? false : true}
          makeDefault
          onChange={handleTransformInput}
          onStart={handleTransformStartInput}
          onEnd={handleTransformEndInput}

          // autoRotate={autoRotate}
          // autoRotateSpeed={-0.1}
          // zoomSpeed={0.25}
          // minZoom={40}
          // maxZoom={140}
          // // enablePan={false}
          // // enableDamping={true}
          // smoothTime={0}
          draggingSmoothTime={.05}
          // minPolarAngle={Math.PI * .05}
          // maxPolarAngle={Math.PI / 1.95}
        />



        <Env />

        <Gizmo
          cameraRef={cameraRef}
        />
        {/*<CycleRaycast onChanged={(objects, cycle) => set({ objects, cycle })} />*/}
        <CycleRaycast
          preventDefault={true} // Call event.preventDefault() (default: true)
          scroll={true} // Wheel events (default: true)
          keyCode={null} // Keyboard events (default: 9 [Tab])
          onChanged={(intersectingObjects, cycle) => {
            // console.log("inter", intersectingObjects?.[0]?.object?.uid, intersectingObjects)
            setHoveredSelection(intersectingObjects)
          }}
        />

        {isExporting &&
          <Exporter
            objects={objectsRef}
            type={isExporting}
            setIsExporting={setIsExporting}
            previewRef={previewRef}
          />
        }
      </Canvas>
    </>
  )
}

function Env() {
  const sceneStore = useStoreContext()
  const [preset, setPreset] = useState('city')

  // You can use the "inTransition" boolean to react to the loading in-between state,
  // For instance by showing a message
  const [inTransition, startTransition] = useTransition()

  const { grid, bloom, threshold, transparent, radius, ambient, background, shadow, position, backgroundBlurriness } = useControls("Scene", {
    position: {value: [0, 10, -10]},
    shadow: { value: '#cccccc' },
    radius: { value: 10, min: 0, max: 20 },
    ambient: { value: 0.5, min: 0, max: 1 },
    bloom: { value: 2, min: 0, max: 10 },
    threshold: { value: 1, min: 0, max: 2 },
    background: { value: '#bbb' },
    transparent: {value: false},
    grid: {value: true},
    backgroundBlurriness: { value: 0.65, min: 0, max: 1 },
    preset: {
      value: preset,
      options: ['sunset', 'dawn', 'night', 'warehouse', 'forest', 'apartment', 'studio', 'city', 'park', 'lobby'],
      // If onChange is present the value will not be reactive, see https://github.com/pmndrs/leva/blob/main/docs/advanced/controlled-inputs.md#onchange
      // Instead we transition the preset value, which will prevents the suspense bound from triggering its fallback
      // That way we can hang onto the current environment until the new one has finished loading ...
      onChange: (value) => startTransition(() => setPreset(value))
    }
    // outlines: {value: true}
  },
    { collapsed: true },
    { store: sceneStore }
  )


  return (
    <>

      {/** Soft shadows */}

      {/*<AccumulativeShadows resolution={1024} blend={100} frames={100}  limit={isDrawing ? 1 : 100}  color={color} colorBlend={1.5} toneMapped={true} alphaTest={0.68} opacity={1} scale={100} position={[0, 0, 0]}>
        <RandomizedLight radius={radius} ambient={ambient} size={15} mapSize={1024} bias={0.001} position={position} />
      </AccumulativeShadows>*/}


      { transparent &&
        <color attach="background" args={[background]} />
      }

      {/** the grid */}
      { grid &&
        <Grid />
      }

      <AccumulativeShadows
        temporal
        frames={100}
        color={shadow}
        colorBlend={2}
        toneMapped={true}
        alphaTest={0.7}
        opacity={1}
        scale={12}
        position={[0, 0, 0]}
      >
        <RandomizedLight amount={8} radius={radius} ambient={ambient} position={[5, 5, -10]} bias={0.001} />
      </AccumulativeShadows>


      {/*really nice shadows on the bottom*/}
      <ContactShadows resolution={1024} position={[0, 0, 0]} opacity={1} scale={radius*2} blur={1} far={0.8} />

      <ambientLight intensity={ambient * Math.PI} />

      <spotLight decay={0} position={[10, 5, -10]} angle={0.15} penumbra={1}>
        {/*<Helper type={PointLightHelper} />*/}
      </spotLight>

      <pointLight decay={0} position={[-10, -10, -10]}>
        {/*<Helper type={PointLightHelper} />*/}
      </pointLight>

      <spotLight decay={0} position={[10, -5, 10]} angle={0.15} penumbra={1}>
        {/*<Helper type={PointLightHelper} />*/}
      </spotLight>

      <pointLight decay={0} position={[-10, 10, 10]}>
        {/*<Helper type={PointLightHelper} />*/}
      </pointLight>


      {/* see: https://codesandbox.io/p/sandbox/environment-blur-and-transitions-pj7zjq? */}
      <Environment
        preset={preset}

        background={transparent ? false : true}
        backgroundBlurriness={backgroundBlurriness} // optional blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
        // backgroundIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
        // environmentIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
      />

      {/*<Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />*/}

      {/* sky */}
      {/*<Sky />*/}


      <EffectComposer
        // depthBuffer={true}
        // autoClear={true}
        renderPriority={1000}
      >
        <Bloom luminanceThreshold={threshold} intensity={bloom} levels={9} mipmapBlur />
      </EffectComposer>


{/*
      <Environment resolution={32}>
        <group rotation={[-Math.PI / 4, -0.3, 0]}>
          <Lightformer intensity={20} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[10, 2, 1]} />
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[10, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[20, 2, 1]} />
          <Lightformer type="ring" intensity={2} rotation-y={Math.PI / 2} position={[-0.1, -1, -5]} scale={10} />
        </group>
      </Environment>
*/}
    </>
  )
}

