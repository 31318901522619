import * as React from 'react'

import { createRoot } from 'react-dom/client'
import './index.css'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Main from './main'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <Main />,
  },
])

createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
)