// see: view-source:https://threejs.org/examples/misc_exporter_usdz.html
// import { GLTFExporter } from 'three-stdlib'
import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js'
import { USDZExporter } from 'three/addons/exporters/USDZExporter.js'

import { Vector3 } from 'three'

import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import { toast } from 'sonner'
// import { useObjectsContext } from "./SceneContext"


export function Exporter({previewRef, objects, type="gltf", setIsExporting}) {
  const sceneWhole = useThree((state) => state.scene)
  console.log('scene', sceneWhole)

  useEffect(() => {
    if (objects.current.children.length) {

      // const exportScene = objects.current
      const exportScene = sceneWhole

      // console.log("objects", objects.current)
      // figure out how not to export invisible r3f stuff
      if (type === "usdz" || type === "preview") {
        const exporter = new USDZExporter()

        console.log('export scene', exportScene, objects)
        // objects.current.scale.set(.1, .1, .1)
        // exportScene.scale.set(.1, .1, .1)

        // objects.current.children.map(child => {
        //   child.scale.set(.1, .1, .1)
        // })

        exporter.parse(exportScene).then(arraybuffer => {
          // console.log("result", arraybuffer)
          if (type === "preview") {
            previewArrayBuffer(previewRef, arraybuffer)
          } else {
            saveArrayBuffer(arraybuffer, "scene.usdz")
          }
          setIsExporting(false)
          toast(`exported scene!`)
        })
      } else if (type === "gltf") {
        // assume gltf
        const exporter = new GLTFExporter()

        exporter.parse(exportScene, async function(arraybuffer) {
          // console.log("result", arraybuffer)
          saveArrayBuffer(arraybuffer, "scene.glb")
          setIsExporting(false)
        },
        // called when there is an error in the generation
        function (error) {
          console.log('An error happened', error)
        },
          {binary: true}
        )
      } else {
        toast(`export type not supported yet: ${type}`)
      }

    } else {
      console.log("nada")
      toast("nothing in scene to export")
    }
  }, [type, objects])

  return null
}

function saveArrayBuffer(buffer, filename) {
  save(new Blob([buffer], { type: "application/octet-stream" }), filename)
}

function save(blob, filename) {
  const link = document.createElement("a")
  link.style.display = "none"
  document.body.appendChild(link) // Firefox workaround, see #6594

  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
}

export function previewArrayBuffer(previewRef, buffer) {
  const blob = new Blob([buffer], { type: "application/octet-stream" })

  // console.log("preview", previewRef.current)
  previewRef.current.rel = "ar"
  previewRef.current.href = URL.createObjectURL(blob)
  previewRef.current.click()
}


export function PNGExporter() {
  // Save the canvas as a *.png

  const link = document.createElement("a")
  link.style.display = "none"
  document.body.appendChild(link) // Firefox workaround, see #6594

  link.setAttribute('download', 'canvas.png')
  link.setAttribute('href', document.querySelector('canvas').toDataURL('image/png').replace('image/png', 'image/octet-stream'))
  link.click()
}