import MaterialSymbol from "./MaterialSymbol"

export default function IconButton({name, src, icon, className, isActive=false, onClick}) {
  return (
    <button
      onClick={onClick}
      title={name}
    >
      {src ?
        <img
          className={`${className} ${isActive ? "text-white bg-white/40": ""} backdrop-blur-sm p-1.5 rounded-full hover:text-white hover:bg-white/40 pointer-events-auto`}
          src={src}
        />
        :
        <MaterialSymbol
          className={`${className} ${isActive ? "text-white bg-white/40": ""} backdrop-blur-sm p-1.5 rounded-full hover:text-white hover:bg-white/40 pointer-events-auto`}
          name={icon}
        />
      }
    </button>
  )
}
