import { useRef, useEffect } from 'react'

export function generateUID() {
    return Math.random().toString(16).slice(2)
}

export function randomChoice(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function randomNumber(a=0, b=1, integer=false) {
  const randomNumber = mapRange(Math.random(), 0, 1, a, b);
  // console.log("random", randomNumber)
  return integer ? round(randomNumber) : randomNumber
}

export function mapRange(value, fromLow, fromHigh, toLow, toHigh) {
  return toLow + (((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow));
}

export function round(value, decimals, increment=null, min=null, max=null) {

  if (decimals == null) { decimals = 0; }
  const d = Math.pow(10, decimals);

  if (increment) { value = Math.round(value / increment) * increment; }
  value = Math.round(value * d) / d;

  if (min && (value < min)) { return min; }
  if (max && (value > max)) { return max; }
  return value;
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value //assign the value of ref to the argument
  },[value]) //this code will run when the value of 'value' changes
  return ref.current //in the end, return the current ref value.
}

export function debounce(threshold, fn, immediate) {
  console.log("try debounce")
  if (threshold == null) { threshold = 0.1; }
  let timeout = null;
  threshold *= 1000;

  return function(...args) {
    const obj = this;
    const delayed = function() {
      if (!immediate) { fn.apply(obj, args); }
      return timeout = null;
    };
    if (timeout) {
      clearTimeout(timeout);
    } else if (immediate) {
      fn.apply(obj, args);
    }
    return timeout = setTimeout(delayed, threshold);
  }
}


export function getFileExtension(filename) {
  const result = filename.match(/\.\w+$/)
  return result ? result[0] : null
}

export function getFileWithoutExtension(filename) {
  return filename.replace(/^(.+?)(\.\w+)?$/, '$1')
}


export function isIOS() {
  const testUA = /iPad|iPhone|iPod/.test(navigator.userAgent)

  var isVisionProUser = false;

  const isMacintosh = navigator.userAgent.includes("Macintosh")
  const hasFiveTouchPoints = navigator.maxTouchPoints === 5

  if (isMacintosh && hasFiveTouchPoints) {
      isVisionProUser = true
  } else {
      isVisionProUser = false
  }

  // console.log('ua', testUA)
  return testUA || isVisionProUser
}