import { useState } from 'react'
import { CameraContext, SelectionContext, ObjectsContext, DraggingSelectionContext } from './SceneContext'
import { useCreateStore, LevaStoreProvider } from 'leva'

// Combined Provider Component
const SceneProvider = ({ children }) => {
  const [transformInput, setTransformInput] = useState([true, true, false])
  const [objects, setObjects] = useState({})
  const [selection, setSelection] = useState(null)
  const [draggingSelection, setDraggingSelection] = useState(null)
  const mainStore = useCreateStore()

  return (
    <LevaStoreProvider store={mainStore}>  
      <CameraContext.Provider value={[transformInput, setTransformInput]}>
        <ObjectsContext.Provider value={[objects, setObjects]}>
          <SelectionContext.Provider value={[selection, setSelection]}>
            <DraggingSelectionContext.Provider value={[draggingSelection, setDraggingSelection]}>
              {children}
            </DraggingSelectionContext.Provider>
          </SelectionContext.Provider>
        </ObjectsContext.Provider>
      </CameraContext.Provider>
    </LevaStoreProvider>
  )
}

export default SceneProvider