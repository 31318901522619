// const colors = useControls(
//   {
//     colors: folder({
//       elevation1: 'rgba(255,255,255,.1)',
//       elevation2: 'rgba(255,255,255, 0)',
//       elevation3: 'rgba(255,255,255, .2)',
//       accent1: 'rgba(255,255,255,1)',
//       accent2: 'rgba(255,255,255,.4)',
//       accent3: 'rgba(255,255,255,1)',
//       highlight1: '#535760',
//       highlight2: 'rgba(255,255,255,1)',
//       highlight3: 'rgba(255,255,255,1)',

//     })
//   },
//   { store: themedStore }
// )

// const radii = useControls(
//   {
//     radii: folder({
//       xs: '2px',
//       sm: '3px',
//       lg: '10px',
//     })
//   },
//   { store: themedStore }
// )

const colors = {
    elevation1: 'rgba(255,255,255,.1)',
    elevation2: 'rgba(255,255,255, 0)',
    elevation3: 'rgba(255,255,255, .2)',
    accent1: 'rgba(255,255,255,1)',
    accent2: 'rgba(255,255,255,.4)',
    accent3: 'rgba(255,255,255,1)',
    highlight1: '#535760',
    highlight2: 'rgba(255,255,255,1)',
    highlight3: 'rgba(255,255,255,1)',
}

export const levaTheme = { colors }