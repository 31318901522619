import { useEffect, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3, Euler } from 'three'

import {
  Html,
  Billboard,
} from '@react-three/drei'

import { AnimatePresence, motion } from 'framer-motion'
import { HexColorPicker } from 'react-colorful'

// get only returns the initial useControls like p,r,s. use store to get "Materials.color" from the store value path
export default function Hud({store, get, size, padding=0.2, selected}) {
  const camera = useThree(state => state.camera)
  const [flip, setFlip] = useState(1)

  // useEffect(() => {
  //   function getColor(color) {
  //     console.log('color get', color)
  //   }
  //   // color={store?.get("Material.color") || color }
  //   // console.log("color", store, store?.get("Material.color"))
  //   store.subscribeToEditStart("Material.color", getColor)
  //   store.subscribeToEditEnd("Material.color", getColor)

  // }, [store])

  // flip hud on angle to camera
  useFrame(() => {
    if (!selected) return //don't spam

    const angle = new Vector3(get['position'][0], 0, get['position'][2]).angleTo(new Vector3(camera.position.x, 0, camera.position.z))

    const objectForward = new Vector3(0, 0, 1) // Object's local -Z axis
    objectForward.applyEuler(new Euler(...get['rotation'], 'XYZ')) // Apply object's rotation

    // Calculate the vector from the object to the camera
    const toCamera = new Vector3().subVectors(camera.position, new Vector3(...get["position"]))

    // Normalize both vectors
    objectForward.normalize()
    toCamera.normalize()

    // Calculate the dot product
    const dot = objectForward.dot(toCamera)

    // If dot is positive, object is facing towards the camera
    if (dot > 0) {
      // console.log('dot > ', dot)
      setFlip(1)
    } else {
      // console.log('dot <', dot)
      setFlip(-1)
    }
  })


  return (
    <group
      rotation={get["rotation"] || [0, 0, 0]}
      position={[
        get["position"][0],
        get["position"][1],
        get["position"][2],
      ]}
    >
      <Billboard
        follow={true}
        lockX={false}
        lockY={true}
        lockZ={true} // Lock the rotation on the z axis (default=false)
        position={[
          size?.[0]/2 + padding || (get["size"][0]/2 + padding) * flip,
          size?.[1]/2 || get["size"][1]/2,
          size?.[2]/2 || get["size"][2]/2 * flip
        ]}
      >
        <Html
          rotation={[0, 0, 0]}
        >
          <AnimatePresence>
            {selected && store?.get("Material.color") && 
              <motion.div
                style={{ originX: 0, originY: 0 }}
                initial={{scale: 0,}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                transition={{type: "spring", stiffness: 400, damping: 24}}
              >
                <HexColorPicker
                  onPointerDown={(e) => {
                    e.stopPropagation()
                  }}
                  className="z-20 picker rounded-lg origin-top-left scale-75 drop-shadow-[0_64px_128px_rgba(0,0,0,0.5)]"
                  color={store?.get("Material.color")}
                  onChange={(color) => {
                    store?.set({"Material.color": color })
                  }}
                />
              </motion.div>
            }
          </AnimatePresence>
        </Html>
      </Billboard>
    </group>
  )
}